<template>
  <v-container id="table-catalog-accounting">
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="ma-0">
          <v-card-title>
            {{ $t('report.accounting.catalog.table.title') }}
            <v-spacer />
            <table-exporter :headers="headers" :items="products" />
            <table-printer element-id="table-catalog-accounting" />
          </v-card-title>
          <v-data-table :headers="headers" :options="options" :items="products" :loading="loading" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import TableExporter from '@/views/components/common/TableExporter';
  import TablePrinter from '@/views/components/common/TablePrinter';

  export default {
    name: 'CatalogAccounting',
    components: { TableExporter, TablePrinter },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        loading: false,
        headers: [
          {
            text: this.$i18n.t('report.accounting.catalog.table.name'),
            align: 'start',
            filterable: true,
            sortable: true,
            value: 'name'
          },
          {
            text: this.$i18n.t('report.accounting.catalog.table.taxRates'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'taxRates'
          },
          {
            text: this.$i18n.t('report.accounting.catalog.table.priceTaxExcluded'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'priceTaxExcluded'
          },
          {
            text: this.$i18n.t('report.accounting.catalog.table.taxes'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'taxes'
          },
          {
            text: this.$i18n.t('report.accounting.catalog.table.priceTaxIncluded'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'priceTaxIncluded'
          }
        ],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['name'],
          sortDesc: [false],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        products: [],
        total: 0
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'accounting/partner/catalog', { timeout: 60000 });
          if (response.data !== undefined && response.data.products !== undefined) {
            this.products = response.data.products;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('report.error'));
        }
        this.loading = false;
      }
    }
  };
</script>
