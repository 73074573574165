<template>
  <v-container id="dashboard" fluid tag="section">
    <alert-notification :message="alert" />
    <v-card color="tabBackgroundColor">
      <v-tabs color="deep-blue accent-4" background-color="transparent" centered grow show-arrows @change="onTabChange">
        <v-tab>{{ $t('report.accounting.dailyRevenues.title') }}</v-tab>
        <v-tab>
          {{ $t('report.accounting.productsSold.title') }}
        </v-tab>
        <v-tab>
          {{ $t('report.accounting.catalog.title') }}
        </v-tab>
        <v-btn icon medium dark color="black" :loading="loading" :disabled="loading" @click.stop="refreshCurrentTab">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-tab-item key="1">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <daily-revenues-accounting :refresh="refreshDailyRevenues" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="2">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <products-sold-accounting :refresh="refreshProductsSold" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="3">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <catalog-accounting :refresh="refreshCatalog" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import DailyRevenuesAccounting from '@/views/components/business/accounting/DailyRevenuesAccounting';
  import ProductsSoldAccounting from '@/views/components/business/accounting/ProductsSoldAccounting';
  import CatalogAccounting from '@/views/components/business/accounting/CatalogAccounting';

  export default {
    name: 'Accounting',
    components: { AlertNotification, DailyRevenuesAccounting, ProductsSoldAccounting, CatalogAccounting },
    data () {
      return {
        alert: '',
        currentTab: 0,
        loading: false,
        refreshDailyRevenues: null,
        refreshProductsSold: null,
        refreshCatalog: null
      };
    },
    mounted () {},
    methods: {
      refreshCurrentTab () {
        this.onTabChange(this.currentTab);
      },
      onTabChange (tab) {
        switch (tab) {
          case 0:
            this.refreshDailyRevenues = Date.now();
            break;
          case 1:
            this.refreshProductsSold = Date.now();
            break;
          case 2:
            this.refreshCatalog = Date.now();
            break;
        }
        this.loading = true;
        this.currentTab = tab;
      },
      success () {
        this.alert = '';
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
        this.loading = false;
      }
    }
  };
</script>
