<template>
  <v-container>
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" submit="manual" :loading="loading" @update="updatePeriod" />
    <v-row v-if="Array.isArray(rates) && rates.length > 0 && typeof total.general.baseTaxExcluded === 'object'">
      <v-col v-for="rate in rates" :key="rate" :cols="12 / rates.length" class="text-center">
        <span>{{ $i18n.t('report.accounting.productsSold.table.baseTaxExcluded') + ' ' + rate }} : </span><span>{{ total.general.baseTaxExcluded[rate].amount }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="ma-0">
          <v-card-title>
            {{ $t('report.accounting.productsSold.table.title') }}
            <v-spacer />
            <table-exporter :data="exportData" />
          </v-card-title>
          <v-data-table :headers="tableHeaders" :options="tableOptions" :items="products.concat(options).concat(fees)" :loading="loading" :mobile-breakpoint="0" fixed-header>
            <template slot="body.prepend">
              <tr>
                <th class="text-uppercase">
                  {{ $t('report.accounting.productsSold.table.total') }}
                </th>
                <th class="text-center">
                  {{ total.general.quantity }}
                </th>
                <th />
                <th />
                <th />
                <th />
                <th class="text-center">
                  {{ total.general.totalPriceTaxExcluded }}
                </th>
                <th class="text-center">
                  {{ total.general.totalTaxes }}
                </th>
                <th class="text-center">
                  {{ total.general.totalPriceTaxIncluded }}
                </th>
              </tr>
            </template>
            <template #[`group.header`]="{items, isOpen, toggle}">
              <th>
                <v-icon @click="toggle">
                  {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
                {{ $t('report.accounting.productsSold.table.type.' + String(items[0].type)) }}
              </th>
              <th class="text-center">
                {{ total[items[0].type].quantity }}
              </th>
              <th />
              <th />
              <th />
              <th />
              <th class="text-center">
                {{ total[items[0].type].totalPriceTaxExcluded }}
              </th>
              <th class="text-center">
                {{ total[items[0].type].totalTaxes }}
              </th>
              <th class="text-center">
                {{ total[items[0].type].totalPriceTaxIncluded }}
              </th>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { displayDateISO, getTodayDate } from '@/util/DateUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';
  import TableExporter from '@/views/components/common/TableExporter';

  export default {
    name: 'ProductsSoldAccounting',
    components: { TableExporter, PeriodSelector },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        startDate: getTodayDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        loading: false,
        tableHeaders: [
          {
            text: this.$i18n.t('report.accounting.productsSold.table.name'),
            align: 'start',
            filterable: true,
            sortable: true,
            value: 'name'
          },
          {
            text: this.$i18n.t('report.accounting.productsSold.table.quantity'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'quantity'
          },
          {
            text: this.$i18n.t('report.accounting.productsSold.table.taxRates'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'taxRates'
          },
          {
            text: this.$i18n.t('report.accounting.productsSold.table.unitPriceTaxExcluded'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'unitPriceTaxExcluded'
          },
          {
            text: this.$i18n.t('report.accounting.productsSold.table.unitTaxes'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'unitTaxes'
          },
          {
            text: this.$i18n.t('report.accounting.productsSold.table.unitPriceTaxIncluded'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'unitPriceTaxIncluded'
          },
          {
            text: this.$i18n.t('report.accounting.productsSold.table.totalPriceTaxExcluded'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'totalPriceTaxExcluded'
          },
          {
            text: this.$i18n.t('report.accounting.productsSold.table.totalTaxes'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'totalTaxes'
          },
          {
            text: this.$i18n.t('report.accounting.productsSold.table.totalPriceTaxIncluded'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'totalPriceTaxIncluded'
          }
        ],
        tableOptions: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['name'],
          sortDesc: [false],
          groupBy: ['type'],
          groupDesc: [true],
          multiSort: false,
          mustSort: true
        },
        total: { general: {}, product: {}, option: {}, fee: {} },
        rates: [],
        products: [],
        options: [],
        fees: []
      };
    },
    computed: {
      exportData () {
        const period = this.$i18n.t('common.time.from') + ' ' + displayDateISO(this.startFullDate) + ' ' + this.$i18n.t('common.time.to') + ' ' + displayDateISO(this.endFullDate);
        const headers = this.tableHeaders.map((header) => header.text);
        const data = [[], []];

        data.push([this.$i18n.t('report.accounting.productsSold.table.results') + ' ' + period]);
        data.push([]);
        data.push(headers);
        data.push(this.exportTotal(this.$i18n.t('report.accounting.productsSold.table.type.product'), 'product'));
        data.push(this.exportTotal(this.$i18n.t('report.accounting.productsSold.table.type.option'), 'option'));
        data.push(this.exportTotal(this.$i18n.t('report.accounting.productsSold.table.type.fee'), 'fee'));
        // data.push(this.exportTotal(this.$i18n.t('report.accounting.productsSold.table.total'), 'general'));
        data.push([]);
        data.push([]);
        if (Array.isArray(this.rates) && this.rates.length > 0 && typeof this.total.general.baseTaxExcluded === 'object') {
          this.rates.forEach((rate) => {
            data.push(['', '', '', '', '', '', '', this.$i18n.t('report.accounting.productsSold.table.baseTaxExcluded') + ' ' + rate, this.total.general.baseTaxExcluded[rate].amount]);
          });
        }
        data.push([]);
        data.push(['', '', '', '', '', '', '', this.$i18n.t('report.accounting.productsSold.table.totalPriceTaxExcluded'), this.total.general.totalPriceTaxExcluded]);
        if (Array.isArray(this.rates) && this.rates.length > 0 && typeof this.total.general.taxes === 'object') {
          this.rates.forEach((rate) => {
            data.push(['', '', '', '', '', '', '', this.$i18n.t('report.accounting.productsSold.table.tax') + ' ' + rate, this.total.general.taxes[rate].amount]);
          });
        }
        data.push(['', '', '', '', '', '', '', this.$i18n.t('report.accounting.productsSold.table.totalPriceTaxIncluded'), this.total.general.totalPriceTaxIncluded]);
        data.push([]);
        data.push([]);
        data.push([]);
        data.push([this.$i18n.t('report.accounting.productsSold.table.details') + ' ' + period]);
        data.push([]);

        data.push(headers);
        this.products.forEach((product) => {
          const item = Object.values(product);
          item.pop();
          data.push(item);
        });
        this.options.forEach((option) => {
          const item = Object.values(option);
          item.pop();
          data.push(item);
        });
        this.fees.forEach((fee) => {
          const item = Object.values(fee);
          item.pop();
          data.push(item);
        });

        return data;
      }
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      exportTotal (title, type) {
        return [
          title,
          this.total[type].quantity,
          '', '', '', '',
          this.total[type].totalPriceTaxExcluded,
          this.total[type].totalTaxes,
          this.total[type].totalPriceTaxIncluded
        ];
      },
      async refreshData () {
        if (this.startFullDate === null || this.endFullDate === null || this.loading) {
          return;
        }

        this.loading = true;
        try {
          const fromDate = encodeURIComponent(this.startFullDate);
          const toDate = encodeURIComponent(this.endFullDate);
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'accounting/partner/product/sold?from=' + fromDate + '&to=' + toDate, { timeout: 60000 });
          if (response.data !== undefined && response.data.products !== undefined && response.data.options !== undefined && response.data.fees !== undefined) {
            this.total.general = response.data.total;
            this.rates = typeof this.total.general.baseTaxExcluded === 'object' ? Object.keys(this.total.general.baseTaxExcluded) : [];
            this.rates.sort((r1, r2) => this.total.general.baseTaxExcluded[r1].taxRate - this.total.general.baseTaxExcluded[r2].taxRate);
            this.total.product = response.data.products.total;
            this.products = response.data.products.list;
            this.products.forEach(function (product) {
              product.type = 'product';
            });
            this.total.option = response.data.options.total;
            this.options = response.data.options.list;
            this.options.forEach(function (option) {
              option.type = 'option';
            });
            this.total.fee = response.data.fees.total;
            this.fees = response.data.fees.list;
            this.fees.forEach(function (option) {
              option.type = 'fee';
            });
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('report.error'));
        }
        this.loading = false;
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      }
    }
  };
</script>
